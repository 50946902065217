@import '../../../styles/variables';

.tools {
    &__disciplines {
        margin-top: 1.5rem;
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
        width: 100%;
        max-width: 650px;
    }

    &__discipline {
        margin-right: 1rem;
        margin-bottom: 1.5rem;
    }

    &__tools {
        padding-left: 0;
        margin: 1.125rem 0 1rem 0;
    }

    &__tool {
        list-style: none;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        font-weight: 500;
        color: var(--color-sea-green-900);
    }

    &__icon {
        color: var(--color-sea-green-600);
        margin-right: .75rem;
    }
}