@import '../../styles/variables';

:root {
    --timezones-dark-blue: #404664;
    --timezones-light-blue: #E8EDF7;
}

.timezones {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    height: 100vh;
    background-color: #F8F9FB;

    &__tag {
        font-size: 10px;
        letter-spacing: .5px;
        color: var(--timezones-dark-blue);
        background-color: var(--timezones-light-blue);
        padding: 4px 6px 3px 6px;
        border-radius: 2px;
    }

    .main-content {
        margin-top: 1.75rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 390px;
        z-index: 1;
    }

    .timezone-bg {
        position: absolute;
        width: 100%;
        max-width: 393px;
    }

    h1 {
        color: var(--timezones-dark-blue);
        font-weight: 600 !important;
        font-size: 1.95rem !important;
        letter-spacing: 0px !important;
        margin-top: .5rem;
        margin-bottom: 1.375rem;
        line-height: 55px !important;
        text-align: center;
    }

    &__card {
        display: flex;
        align-items: center;
        width: 100%;
        border: solid 1px rgba(64, 70, 100, .07);
        margin-bottom: 1.25rem;
        border-radius: 4px;

        &--oxford {
            background-color: rgba(#FCFBF7, .65);
        }

        &--sydney {
            background-color: rgba(#F8FBFB, .65);
        }
    }

    &__illustration--oxford,
    &__illustration--sydney {
        padding-left: 1px;
        display: inline-flex;
        align-items: center;
        min-height: 107px;
        height: 100%;
    }

    &__illustration--oxford {
        position: relative;
        background-color: #F6F1E4;
        
        .region_title {
            position: absolute;
            top: 2px;
            color: #D3C3AE;
        }
    }

    &__illustration--sydney {
        position: relative;
        background-color: #E2F0F3;
        
        .region_title {
            position: absolute;
            top: 2px;
            color: #86C6D5;
        }
    }

    .region_time_date_container {
        display: flex;
        flex-direction: column;
        margin: 1rem 1rem 1.5rem 1.25rem;
    }

    .region_title {
        font-family: 'Jost';
        font-size: 25px;
        letter-spacing: -.25px;
        font-weight: bold;
        width: 100%;
        text-align: center;
        z-index: 0;

        #text:first-of-type {
            font-size: 2rem;
        }
    }

    .region_img {
        width: 100px;
        height: 100px;
        z-index: 1;
    }

    .region_time {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: .25rem;
    }

    .region_date {
        font-size: 1rem;
        font-weight: 300;

        @media (max-width: 390px) {
            font-size: .875rem;
        }
    }
    
    .timezones__card--oxford .region_time,
    .timezones__card--oxford .region_date {
        color: #776855;
    }
    
    .timezones__card--sydney .region_time,
    .timezones__card--sydney .region_date {
        color: #0095B6;
    }

    .slider-fixed-container {
        position: fixed;
        bottom: 0;
        display: flex;
        justify-content: center;
        width: 100%;
        z-index: 3;
    }

    .slider-container {
        width: 100%;
        max-width: 390px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: solid 1px rgba(64, 70, 100, .07);
        padding: .75rem 0 0 0;
        margin: 0 15px;
        background-color: rgba(#F8F9FB,.8);
        border-radius: 4px;
        z-index: 1;
        user-select: none;
    }

    .slider-title {
        font-family: 'Jost';
        font-size: 1rem;
        font-weight: 600;
        color: var(--timezones-dark-blue);
        margin-bottom: .125rem;
        user-select: none;
    }

    .slider-diff {
        font-family: 'Jost';
        font-size: .875rem;
        color: #7B83AD;
        margin-bottom: 1rem;
        user-select: none;
    }

    .rc-slider-handle,
    .rc-handle-dragging {
        background: linear-gradient(125deg, #404664, #7A8099);
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25) !important;
        height: 28px;
        width: 28px;
        opacity: 100 !important;
        top: -2px;
        border: none;
    }

    .rc-slider-rail,
    .rc-slider-track {
        background-color: #B1B6CD;
        border-radius: 0;
    }

    .slider {
        margin-bottom: -6px;
    }

    .slider-bottom {
        background: linear-gradient(90deg, #D1D8E6 0.08%, #E8EDF7 99.94%);
        width: 100%;
        height: 2rem;
    }
}