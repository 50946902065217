@font-face {
    font-family: 'Jost';
    src: url(../fonts/Jost/Jost-Black.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url(../fonts/Jost/Jost-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url(../fonts/Jost/Jost-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url(../fonts/Jost/Jost-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url(../fonts/Jost/Jost-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url(../fonts/Jost/Jost-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url(../fonts/Jost/Jost-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url(../fonts/Jost/Jost-ExtraLight.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url(../fonts/Jost/Jost-Thin.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}