@import '../../../styles/variables';

.jobs {
    &__main {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        
        @media (max-width: $breakpoint-md) {
            flex-direction: column;
        }
    }

    &__item {
        display: flex;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        border-bottom: solid 1px var(--color-sea-green-200);

        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 1.5rem;
            border-bottom: none;
        }
    }

    &__title {
        font-size: 1.125rem;
        line-height: 140%;
        font-weight: 500;
        color: var(--color-sea-green-900);
        margin-top: .125rem;
    }

    &__detail {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;

        @media (max-width: $breakpoint-sm) {
            margin-left: 0;
        }
    }

    &__icon {
        color: var(--color-sea-green-600);
        margin-top: .125rem;

        @media (max-width: $breakpoint-sm) {
            display: none;
        }
    }

    &__date, &__company {
        display: flex;
        flex-flow: wrap;
        font-size: 1rem;
        margin-top: .35rem;
        color: var(--color-sea-green-800);
        
        @media (max-width: $breakpoint-sm) {
            flex-direction: column;

            .education__link-text {
                margin-left: 0;
            }
        }
    }

    &__description {
        margin-top: .5rem;

        &:last-child {
            margin-bottom: 0;
        }

        &:not(:first-child) + :last-child {
            margin: 0;
        }
    }
}