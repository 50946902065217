@import '../../styles/variables';

.nav-bar {
    width: 100%;
    position: absolute;
    top: 0;
    -webkit-transition: 250ms ease-out;
    -moz-transition: 250ms ease-out;
    -o-transition: 250ms ease-out;
    transition: 250ms ease-out;
    z-index: 100;
    background-color: transparent;
  }
  
  .nav-bar__inner {
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: 948px; 
    padding: 1.125rem 0;
    
    @media (max-width: $breakpoint-xl) {
        margin: 0 3rem;
        padding: 1.125rem 0.75rem;
    }
  }
  
  .nav-bar--hidden {
    top: -80px;
  }

  .nav-bar__links {
      display: none;
  }

  .nav-bar__contact {
      display: flex;
      align-items: center;
  }

  .nav-bar__icon {
    &:first-of-type {
        margin-right: 3rem;
    }

    &:hover .nav-bar__linkedin,
    &:hover .nav-bar__email {
      color: var(--color-sea-green-100);
      fill: var(--color-sea-green-100);
    }
  }

  .nav-bar__linkedin,
  .nav-bar__email {
      color: var(--color-sea-green-400);
      fill: var(--color-sea-green-400);
      -webkit-transition: 100ms ease-out;
      -moz-transition: 100ms ease-out;
      -o-transition: 100ms ease-out;
      transition: 100ms ease-out;
  }

.nav-bar--bg {
    background-color: rgba(var(--value-sea-green-100), .9);

    .nav-bar__inner {
        justify-content: space-between;
    }

    .nav-bar__links {
        display: flex;
        align-items: center;
    }

    .nav-bar__link {
        text-decoration: none;
        margin-right: 3rem;
        line-height: 100%;
    }

    .nav-bar__link-text {
      font-weight: 500;
      font-size: 1.125rem;
      font-family: Jost;
      line-height: 100%;
      text-decoration: none;
      color: var(--color-sea-green-800);
      display: flex;
      align-items: center;
      -webkit-transition: 100ms ease-out;
      -moz-transition: 100ms ease-out;
      -o-transition: 100ms ease-out;
      transition: 100ms ease-out;

      .material-icons {
        font-size: .875rem;
        margin-left: .325rem;
        color: var(--color-sea-green-400);
        -webkit-transition: 100ms ease-out;
        -moz-transition: 100ms ease-out;
        -o-transition: 100ms ease-out;
        transition: 100ms ease-out;
      }

      &:hover {
        color: var(--color-sea-green-900);
        cursor: pointer;
      }

      &:hover .material-icons {
        color: var(--color-sea-green-600);
        cursor: pointer;
      }
    }

    .nav-bar__icon {    
        &:hover .nav-bar__linkedin,
        &:hover .nav-bar__email {
          color: var(--color-sea-green-800);
          fill: var(--color-sea-green-800);
        }
      }
    
      .nav-bar__linkedin,
      .nav-bar__email {
          color: var(--color-sea-green-600);
          fill: var(--color-sea-green-600);
      }
}