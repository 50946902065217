@import "../../styles/variables";

.proj-gallery {
    min-width: 700px;
    max-width: 968px;
    border-collapse: collapse;
    color: var(--color-sea-green-800);
    margin: 1.5rem auto 0 auto;
    
    @media (min-width: $breakpoint-xl) {
        margin: 1.5rem 0 0 0;

        &__container {
            max-width: 968px;
            margin: 0 auto;
        }
    }
    
    &.proj-gallery--2by2 {
        min-width: 500px;
    }

    &__container {
        overflow: auto;
    }

    &__sticky {
        position: sticky;
        left: 0;
        background: rgba(var(--value-sea-green-100),.85);
    }

    &__heading-left {
        white-space: nowrap;
        width: 3rem;
        display: flex;
        justify-content: center;

        h5 {
            transform: rotate(270deg);
        }
    }

    &__heading-top {
        text-align: left;
    }

    &__thumbnail {
        margin: 1rem 2rem 1rem 0;

        @media (max-width: $breakpoint-sm) {
            margin: .4rem 1rem .4rem 0;
        }
    }

    &__padding-row {
        height: 4rem;
    }
}

.jagged-border__inner-container .proj-gallery__sticky {
    background: rgba(var(--value-sea-green-200),.85);
}