@import '../../../styles/variables';

.education {
    &__main {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        
        @media (max-width: $breakpoint-md) {
            flex-direction: column;
        }
    }

    &__certifications {
        margin: 2.5rem 0 1.5rem 0;
    }

    &__item {
        display: flex;
        margin-bottom: 1.5rem;
    }

    &__title {
        font-size: 1.125rem;
        line-height: 140%;
        font-weight: 500;
        color: var(--color-sea-green-900);
        margin-top: .125rem;
    }

    &__detail {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        
        @media (max-width: $breakpoint-sm) {
            margin-left: 0;
        }
    }

    &__icon {
        color: var(--color-sea-green-600);
        margin-top: .125rem;

        @media (max-width: $breakpoint-sm) {
            display: none;
        }
    }

    &__credentials {
        display: flex;
        flex-flow: wrap;
        font-size: 1rem;
        margin-top: .35rem;
        line-height: 140%;
        color: var(--color-sea-green-800);
        
        @media (max-width: $breakpoint-sm) {
            flex-direction: column;

            .education__link-text {
                margin-left: 0;
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: var(--color-sea-green-800);

        &:hover {
            cursor: pointer;
            color: var(--color-sea-green-900);
        }
    }

    &__link-text {
        text-decoration: underline;
        margin: 0 .25rem 0 .175rem;
    }

    &__link-icon {
        font-size: .875rem;
    }

    &__cv {
        background-color: var(--color-white);
        box-shadow: var(--card-shadow);
        border-radius: 23px;
        padding: 1.5rem 3.5rem;
        margin-left: 1.5rem;
        display: inline-flex;
        align-items: center;
        flex-direction: column;
        text-decoration: none;

        &:hover {
            .education__cv-icon {
                transform: translateY(4px);
                color: var(--color-sea-green-900);
            }
        }
        
        @media (max-width: $breakpoint-md) {
            width: 100%;
            margin: .5rem 0 0 0;
            padding: 1.5rem 0;
        }
    }
}