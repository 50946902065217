@import '../../styles/variables';

.gallery-grid {
    display: flex;
    justify-content: center;
    flex-flow: wrap;

    &__thumbnail {
        width: 33%;
        height: 100%;
        -webkit-transition: 100ms ease-out;
        -moz-transition: 100ms ease-out;
        -o-transition: 100ms ease-out;
        transition: 100ms ease-out;
        
        @media (max-width: $breakpoint-md) {
            width: 50%;
        }

        &:hover {
            cursor: pointer;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
            z-index: 2;
        }
    }
}