@import "_fonts.scss";
@import "_colors.scss";
@import "_variables.scss";
@import "_icons.scss";

html,
body {
  margin: 0;
  background-color: var(--color-sea-green-100);

  ::selection {
    background: var(--color-sea-green-600);
    color: var(--color-white);
    -webkit-text-fill-color: white;
  }

  ::-moz-selection {
    background:  var(--color-sea-green-600);
    color: var(--color-white);
  }
}

.page-contents {
  max-width: 968px;
  margin: 0 auto;
  position: relative;

  @media (max-width: $breakpoint-xl) {
    margin: 0 3rem;
  }

  @media (max-width: $breakpoint-sm) {
    margin: 0 1.25rem;
  }
}

.bullet-list {
  padding-left: 1.75rem;

  li {
    margin-bottom: 0.85rem;
    color: var(--color-sea-green-900);
    list-style-type: none;
    display: flex;
    align-items: center;

    &:before {
      content: url("../assets/bullet.gif");
      margin: 0 0.75rem 0 -16px;
      font-size: 0rem;
    }
  }

  &__text {
    margin-top: 0.25rem;
    line-height: 140%;
    font-size: 1.125rem;
    display: inline-block;
    vertical-align: middle;
    display: flex;
    align-items: center;
  }
}

.iterate-badge {
    display: flex;
    background-color: var(--color-white);
    border-radius: 100px;
    padding: .2rem .625rem .125rem .45rem;
    margin-left: .5rem;
    color: var(--color-sea-green-600);

    &__text {
      font-size: .875rem;
      font-weight: bold;
      font-family: Jost;
      margin-left: .25rem;
      line-height: normal;
    }

    &__icon {
      font-size: .85rem;
      font-weight: bold;
      line-height: 1.25rem;
      transform: rotate(270deg);
    }
}

.table-card {
  text-align: left;
  border-spacing: 0;
  background: var(--color-white);

  &__overflow-container--1 {
    overflow-x: auto;
    margin-left: -24px;
    margin-right: -20px;
    padding-bottom: 1rem;
  }

  &__overflow-container--2 {
    min-width: 545px;
  }

  &__container {
    border-radius: 20px;
    padding: 2rem 2.5rem;
    min-width: 445px;
    margin: 0 1.5rem;
    background: var(--color-white);
    box-shadow: var(--card-shadow);

    @media (max-width: $breakpoint-md) {
      padding: 1.5rem 1.75rem;
    }
  }

  &__heading {
    padding-bottom: 1rem;
  }

  tr:last-of-type .table-card__data {
    padding-bottom: 0;
  }

  &__data {
    border-top: solid 1px var(--color-sea-green-200);
    padding: 1rem 0;
    vertical-align: baseline;
  }

  &__data,
  &__heading {
    &:not(:first-of-type) {
      padding-left: 0.75rem;
    }
  }

  &__text {
    margin: 0;
  }

  &__numbered-cell {
    display: flex;
    align-items: flex-start;

    .table-card__text {
      padding-left: 0.5rem;
    }

    @media (max-width: $breakpoint-sm) {
      h5 {
        padding-top: 2px;
      }
    }
  }
}

.jagged-border {
  &--top {
    width: 100%;
    transform: translateY(5px);
    margin-top: 4rem;
    z-index: 0;
  
    @media (max-width: $breakpoint-md) {
        margin-top: 2rem;
    }
  }
  
  &--bottom {
    width: 100%;
    transform: scale(-1, -1.1);
    z-index: 0;
    margin-bottom: 3rem;
  }
  
  
  &__inner-container {
    background-color: var(--color-sea-green-200);
    z-index: 1;
    padding: 0 0 2.5rem 0;
    
    @media (max-width: $breakpoint-lg) {
        padding: 3rem 0;
    }

    @media (min-width: $breakpoint-xxxxl) {
      padding: 0 0 3rem 0;
    }

    &--bottomless {
      margin-bottom: -6rem;
      padding-bottom: 6rem;
    }
  }
}

.letter-space-reset {
  letter-spacing: 0;
}