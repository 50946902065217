@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-Black.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-Thin.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-BlackItalic.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-BoldItalic.ttf) format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-MediumItalic.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,400&display=swap');

@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-LightItalic.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-ThinItalic.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}