@import "../../styles/variables";
.proj {
    &__container {
        margin-top: 6rem;

        @media (max-width: $breakpoint-md) {
            margin-top: 2rem;
        }
    }
}

.proj {
    &__breadcrumb {
        font-family: Roboto;
        font-size: 1.125rem;
        font-weight: 500;
        color: var(--color-sea-green-800);
        margin-bottom: 1.25rem;
        display: block;

        @media (min-width: $breakpoint-md) {
            display: none;
        }

        .link-text {
            color: var(--color-sea-green-800);
            -webkit-transition: 100ms ease-out;
            -moz-transition: 100ms ease-out;
            -o-transition: 100ms ease-out;
            transition: 100ms ease-out;
            margin: 0 .125rem;

            &:hover {
                color: var(--color-sea-green-800);
            }
        }
    }

    &__meta {
        display: flex;
        align-items: flex-start;
        margin-bottom: 0.75rem;

        &:first-of-type {
            margin-top: 1.5rem;
        }
    }

    &__meta-icon {
        margin-right: 1rem;
        margin-top: .0625rem;
        color: var(--color-sea-green-600);
    }

    &__meta-text {
        margin-bottom: 0;
        color: var(--color-sea-green-800);
        font-size: 1.125rem;
        line-height: 155%;
    }
}

.proj {
    &__intro {
        margin-top: 2rem;
        display: flex;

        @media (max-width: $breakpoint-lg) {
            margin-top: 1rem;
            flex-direction: column-reverse;
        }
    }

    &__hero-pic {
        height: 242px;
        margin: 0 0 0 2rem;

        @media (max-width: $breakpoint-lg) {
            margin: .5rem auto 2rem auto;
            max-width: 430px;
            width: 90%;
            height: auto;
        }
    }
}

.proj {
    &__strategy {
        margin-top: 1.75rem;
    }

    &__heading-3 {
        margin-bottom: 1rem;
    }

    &__bullet-list {
        margin-bottom: 1.5rem;
    }
}

.proj {
    &__solutions {
        margin-top: 3rem;
    }
}


.proj {
    &__problem p {
        max-width: 700px;
    }

    &__problem-sub-heading {
        margin: .25rem 0 1.25rem 0;
    }

    &__gallery {
        margin: 1.5rem 0 0 0;
        display: flex;

        @media (max-width: $breakpoint-sm) {
            flex-direction: column;
            align-items: center;
        }
    }
    
    &__gallery--1-thumbnail-container,
    &__gallery--2-thumbnail-container {
        margin-bottom: 3rem;
        position: relative;
    }

    &__gallery--2-thumbnail-container {
        width: 45%;
        
        @media (max-width: $breakpoint-sm) {
            width: 100%;
            max-width: 400px;
            margin: 0 0 3rem 0 !important;
        }
    
        &:first-of-type {
            margin-right: 2rem;
        }
    }


    &__gallery--1-thumbnail,
    &__gallery--2-thumbnail,
    &__gallery--3-thumbnail {
        border-radius: 10px;
        box-shadow: var(--screenshot-shadow);
    
        -webkit-transition: 100ms ease-out;
        -moz-transition: 100ms ease-out;
        -o-transition: 100ms ease-out;
        transition: 100ms ease-out;

        &:hover {
            cursor: pointer;
            box-shadow: var(--screenshot-shadow-hover);
        }
    }

    &__gallery--1-thumbnail {
        width: 100%;
        max-width: 700px;
    }

    &__gallery--2-thumbnail {
            width: 100%;
    }

    &__gallery--3-thumbnail {
        width: 100%;
    }

    &__gallery--3-row {
        display: flex;
        justify-content: space-between;
    }

    &__gallery-caption {
        position: absolute;
        bottom: -1.5rem;
        left: 1.5rem;
        background: var(--color-white);
        padding: 1rem 1.5rem;
        border-radius: 10px;
        font-style: italic;
        font-weight: 500;
        color: var(--color-sea-green-600);
        
        @media (max-width: $breakpoint-sm) {
            font-size: .85rem;
        }
    }
}

.proj {
    &__conclusion {
        margin-bottom: 1.75rem;
    }
}

.more-projects {
    &__heading {
        margin-bottom: 2rem;    
        margin-top: 2rem;
        padding-top: 3.5rem;
        border-top: solid 1px #B5DCE2;
        width: 100%;
    }
}