@import "../../styles/variables";

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__container {
        background-color: var(--color-sea-green-900);
        padding: 3.5rem 0 4rem 0;
        margin-top: 6rem;

    }

    &__text,
    &__link,
    &__heading {
        color: var(--color-sea-green-100);
        text-decoration: none;
    }

    &__text {
        margin-bottom: 0.75rem;
    }

    &__link {
        margin-left: 0.25rem;

        &:hover {
            text-decoration: underline;
            color: var(--color-white);
        }
    }

    &__contact {
        display: flex;
        align-items: center;
        margin: 1.75rem 0 1.75rem 0;
    }

    &__linkedin,
    &__email {
        color: var(--color-sea-green-100);
        fill: var(--color-sea-green-100);
        -webkit-transition: 100ms ease-out;
        -moz-transition: 100ms ease-out;
        -o-transition: 100ms ease-out;
        transition: 100ms ease-out;
    }

    &__icon {
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        &:first-of-type {
            margin-right: 3rem;
        }

        &:hover .footer__linkedin,
        &:hover .footer__email {
            color: var(--color-white);
            fill: var(--color-white);
        }
    }

    &__nav-links--mobile {
        display: none;
        flex-direction: column;
        margin-bottom: 1.5rem;

        @media (max-width: $breakpoint-md) {
            display: flex;
        }
    }

    &__nav-links--desktop {
        margin-right: 4rem;
        display: flex;
        flex-direction: column;

        @media (max-width: $breakpoint-md) {
            display: none;
        }
    }

    &__nav-link {
        margin-bottom: 1rem;
        color: var(--color-sea-green-100);
        font-weight: 500;
        display: flex;
        align-items: center;

        &:hover {
            color: var(--color-white);
        }
    }

    &__cv-link {
        text-decoration: none;

        &-text {
            text-decoration: underline;
        }

        &-icon {    
            font-size: .875rem;
            margin-left: .325rem;
            color: rgba(var(--value-sea-green-100), .7);
            font-weight: normal;
            -webkit-transition: 100ms ease-out;
            -moz-transition: 100ms ease-out;
            -o-transition: 100ms ease-out;
            transition: 100ms ease-out;
        }

        &:hover {
            .footer__cv-link-icon {
                color: currentColor;
            }
        }
    }
}
