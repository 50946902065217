@import '../../styles/variables';

.about-me {
    &__anchor {
        position: absolute;
        top: -72.5px;
        

        @media (max-width: $breakpoint-md) {
            top: -30px;
        }
    }

    &__profile {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;

        @media (max-width: $breakpoint-sm) {
            flex-direction: column-reverse;
            align-items: center;
        }
    }

    &__profile-pic {
        max-width: 232px;
        max-height: 232px;
        margin: 0 0 0 2rem;

        @media (max-width: $breakpoint-sm) {
            margin: 0 0 2rem 0;
            max-width: 200px;
            max-height: 200px;
        }
    }

    &__paragraphs {
        max-width: 688px;
    }

    &__photography-heading {
        margin: 3rem 0 1.5rem 0;
    }
}