:root {
    --color-white: rgb(255, 255, 255);
    --color-black: rgb(0, 0, 0);

    --value-sea-green-900: 2, 38, 44;
    --value-sea-green-800: 10, 91, 104;
    --value-sea-green-600: 75,144,155;
    --value-sea-green-400: 150, 204, 212;
    --value-sea-green-200: 220, 241, 244;
    --value-sea-green-100: 239, 249, 251;

    --color-sea-green-900: rgb(var(--value-sea-green-900));
    --color-sea-green-800: rgb(var(--value-sea-green-800));
    --color-sea-green-600: rgb(var(--value-sea-green-600));
    --color-sea-green-400: rgb(var(--value-sea-green-400));
    --color-sea-green-200: rgb(var(--value-sea-green-200));
    --color-sea-green-100: rgb(var(--value-sea-green-100));

    --color-sea-green-gradient: -webkit-linear-gradient(-10.76deg, var(--color-sea-green-800) 28.02%, var(--color-sea-green-600) 78.4%, var(--color-sea-green-400) 122.4%);
}