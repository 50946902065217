@import '/src/styles/variables';

.home-hero {
    background:
    url('../../assets/noise.svg'),
    var(--color-sea-green-gradient);
    height: auto;
    padding-bottom: 5rem;

    @media (min-width: 700px) and (min-height: 900px) {
        height: 80vh;
        padding-bottom: 0;
    }

    &__headings {
        padding-top: 8rem;
        
        @media (max-width: $breakpoint-sm) {
            padding-top: 4rem;
        }
    }

    &__heading-6 {
        color: rgba(var(--value-sea-green-600), .60);
        
        @media (max-width: $breakpoint-sm) {
            font-size: .86rem;
        }
    }

    &__heading-5 {
        color: rgba(var(--value-sea-green-600), .75);
        margin-top: .25rem;
        
        @media (max-width: $breakpoint-sm) {
            font-size: 1.09rem;
        }
    }

    &__heading-4 {
        color: var(--color-sea-green-600);
        margin-top: .125rem;
        
        @media (max-width: $breakpoint-sm) {
            font-size: 1.35rem;
            margin-top: .175rem;
        }
    }   

    &__heading-3 {
        color: rgba(var(--value-sea-green-400), .65);
        margin-top: .125rem;
        
        @media (max-width: $breakpoint-sm) {
            font-size: 1.7rem;
            margin-top: .05rem;
        }
    }

    &__heading-2 {
        color: rgba(var(--value-sea-green-200), .75);
        margin-top: .25rem;
        
        @media (max-width: $breakpoint-sm) {
            font-size: 2.12rem;
        }
    }

    &__heading-1 {
        color: var(--color-sea-green-100);
        margin: .25rem 0 8.5rem 0;

        @media (max-width: $breakpoint-sm) {
            margin: .125rem 0 6rem 0;
            font-size: 2.65rem;
        }
    }

    &__link {
        color: var(--color-sea-green-100);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 192px;
        margin-bottom: 1rem;
        text-decoration: none;

        &:hover {
            cursor: pointer;
            color: var(--color-sea-green-100);

            .home-hero__link-icon {
                color: var(--color-sea-green-100);
                transform: translateX(5px);
            }
        }
    }

    &__link-icon {
        font-size: 1.25rem;
        color: var(--color-sea-green-400);
        -webkit-transition: 100ms ease-out;
        -moz-transition: 100ms ease-out;
        -o-transition: 100ms ease-out;
        transition: 100ms ease-out;

        @media (max-width: $breakpoint-sm) {
            font-size: 1.125rem;
        }
    }
}