$breakpoint-xs: 0px;
$breakpoint-a11y: 300px;
$breakpoint-sm: 576px;
$breakpoint-md: 767px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1100px;
$breakpoint-xxl: 1400px;
$breakpoint-xxxl: 1800px;
$breakpoint-xxxxl: 2400px;

:root {
    --card-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    --card-shadow-heavy:  0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 6px -1px rgba(0, 0, 0, 0.2);
    --screenshot-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
    --screenshot-shadow-hover: 0px 25px 50px -12px rgba(0, 0, 0, 0.45);
}