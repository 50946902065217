@import '_variables.scss';
@import '_font_jost.scss';
@import '_font_roboto.scss';
@import '_font_materialicons.scss';



html {
    font-family: Roboto;
}

h1,h2,h3,h4,h5,h6 {
    font-family: Jost;

    @media (max-width: $breakpoint-a11y) { 
        word-break: keep-all;
        hyphens: auto;
    }
}

h1 {
    font-family: Jost;
    font-weight: 600;
    font-size: 4rem;
    letter-spacing: -2px;
    margin: 0;
    line-height: 110%;

    @media (max-width: $breakpoint-sm) { 
        font-size: 2.65rem;
    }
}

h2 {
    font-family: Jost;
    font-weight: 600;
    font-size: 3rem;
    line-height: 110%;
    letter-spacing: -2px;
    margin: 0;

    @media (max-width: $breakpoint-sm) { 
        font-size: 2.25rem;
    }
}

h3 {
    font-family: Jost;
    font-weight: 600;
    font-size: 2rem;
    line-height: 110%;
    letter-spacing: -1px;
    margin: 0;    

    @media (max-width: $breakpoint-sm) { 
        font-size: 1.85rem;
    }
}
  
h4 {
    font-family: Jost;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 110%;
    margin: 0;
    
    @media (max-width: $breakpoint-sm) { 
        // font-size: 1.25rem;
    }
}

h5 {
    font-family: Jost;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 110%;
    margin: 0;
    

    @media (max-width: $breakpoint-sm) { 
        font-size: 1.125rem;
    }
}

h6 {
    font-family: Jost;
    font-weight: 600;
    font-size: 1rem;
    line-height: 110%;
    margin: 0;

    @media (max-width: $breakpoint-sm) { 
        font-size: 0.9rem;
    }
}

.gradient-text {
    background: var(--color-sea-green-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    line-height: 118%;
    padding-right: 3px;
}

.sea-green-text {
    color: var(--color-sea-green-800);
}

p {
    font-family: Roboto;
    font-size: 1.125rem;
    letter-spacing: 0;
    line-height: 164%;
    max-width: 800px;
    margin: 0 0 1.25rem 0;
    color: var(--color-sea-green-900);
}

.tag-text {
    font-size: .9375rem;
    font-weight: 500;
    letter-spacing: 1%;
    text-transform: uppercase;
    color: var(--color-sea-green-600);
}

.link-text {
    font-family: Roboto;
    font-size: 1.125rem;
    font-weight: 500;
    text-decoration: underline;
    
    @media (max-width: $breakpoint-sm) { 
        font-size: 1rem;
    }
}