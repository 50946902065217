@import '../../styles/variables';

.nav-bar--desktop {
  display: inline;
}

.nav-bar--mobile {
  display: none;
}

@media (max-width: $breakpoint-md) {
  .nav-bar--desktop {
    display: none;
  }

  .nav-bar--mobile {
    display: inline;
  }
}