@import '/src/styles/variables';

.back-to-top {
    position: fixed;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 1rem;
    box-shadow: 
    -25px 25px 0px rgba(10, 91, 104, 0.05), 
    -20px 20px 0px rgba(10, 91, 104, 0.1), 
    -15px 15px 0px rgba(10, 91, 104, 0.2), 
    -10px 10px 0px rgba(10, 91, 104, 0.3), 
    -5px 5px 0px rgba(10, 91, 104, 0.4);
    border-radius: 16px;
    z-index: 3;
    
    -webkit-transition: 150ms ease-out;
    -moz-transition: 150ms ease-out;
    -o-transition: 150ms ease-out;
    transition: 150ms ease-out;
    
    right: 35px;
    bottom: 50px;    

    @media (min-width: $breakpoint-xxl) {
        right: 10%;
        bottom: 50px;
    }
    
    @media (min-width: $breakpoint-xxxl) {
        right: 15%;
    }

    @media (min-width: $breakpoint-xxxxl) {
        right: 25%;
    }

    &:active:hover {
        box-shadow: -5px 5px 0px rgba(10, 91, 104, 0.4);
    }

    &:hover {
        cursor: pointer;
        box-shadow: 
        -15px 15px 0px rgba(10, 91, 104, 0.2), 
        -10px 10px 0px rgba(10, 91, 104, 0.3), 
        -5px 5px 0px rgba(10, 91, 104, 0.4);
    }

    &--hidden {
        display: none;
    }

    &__title {
        margin-left: .75rem;
    }

    &__icon {
        color: var(--color-sea-green-800);
    }
}   