@import '../../styles/variables';

.experience {    
    margin-top: 3rem;
    
    &__anchor {
        position: absolute;
        top: -65px;

        @media (max-width: $breakpoint-md) {
            top: -30px;
        }
    }

    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        width: 100%;
        
        @media (max-width: $breakpoint-sm) {
            flex-direction: column;

            .experience__cv {
                margin-top: .75rem;
            }
        }
    }

    &__cv {
        display: inline-flex;
        align-items: center;
        text-decoration: none;

        &:hover {
            .experience__cv-icon {
                transform: translateX(5px);
            }
        }
    }
    
    &__cv-label {
        color: var(--color-sea-green-900);   
    }

    &__cv-icon {
        color: var(--color-sea-green-600);
        font-size: 1.125rem;
        margin-left: 1rem;

        -webkit-transition: 100ms ease-out;
        -moz-transition: 100ms ease-out;
        -o-transition: 100ms ease-out;
        transition: 100ms ease-out;
    }

    &__tabs {
        background: var(--color-white);
        box-shadow: var(--card-shadow);
        border-radius: 29px;
        margin-top: 1.75rem;
    }
    
    &__tab-heading {
        color: var(--color-sea-green-600);
        -webkit-transition: 100ms ease-out;
        -moz-transition: 100ms ease-out;
        -o-transition: 100ms ease-out;
        transition: 100ms ease-out;
        
        @media (max-width: $breakpoint-sm) {
            font-size: 1.5rem;
        }
        
        @media (max-width: 355px) {
            font-size: 1.25rem;
        }
    }
    
    &__tabs .react-tabs {
        &__tab-list {
            border-bottom: solid 1px var(--color-sea-green-200);
        }
    
        &__tab {
            padding: 1.5rem 3rem 1rem 3rem;
            
            @media (max-width: $breakpoint-sm) {
                padding: 1.5rem 1rem 1rem 1rem;
            }

            @media (max-width: 355px) {
                padding: 1.5rem .75rem 1rem .75rem;
            }
    
            &:first-of-type {
                margin-left: 1.5rem;
            }
            
            &:hover .experience__tab-heading {
                color: var(--color-sea-green-800);
            }
        }
    
        &__tab--selected {
            border: none;
            border-bottom: solid 1px var(--color-sea-green-600);
    
            .experience__tab-heading {
                color: var(--color-sea-green-800);
            }
        }
    
        &__tab-panel {
            padding: 1rem 2rem;

            @media (max-width: $breakpoint-sm) {
                padding: 1rem 1.25rem;
            }

            @media (min-width: $breakpoint-lg) {
                min-height: 756px;
            }
        }
    }
}