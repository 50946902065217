@import "../../styles/variables";

.project {
    &__anchor {
        position: absolute;
        top: 12.5px;

        @media (max-width: $breakpoint-md) {
            top: 30px;
        }
    }
}

.project__heading {
    margin: 5rem 0 2rem 0;

    @media (max-width: $breakpoint-md) {
        margin: 3.5rem 0 2rem 0;
    }
}

.project-card {
    background-color: var(--color-white);
    box-shadow: var(--card-shadow);
    border-radius: 29px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    text-decoration: none;

    -webkit-transition: 100ms ease-out;
    -moz-transition: 100ms ease-out;
    -o-transition: 100ms ease-out;
    transition: 100ms ease-out;

    @media (max-width: $breakpoint-md) {
        flex-direction: column;
        align-items: flex-start;
    }

    &:hover {
        cursor: pointer;
        box-shadow: var(--card-shadow-heavy);

        .project-card__icon {
            transform: translate(10px, 6px);
            color: var(--color-sea-green-800);
        }
    }

    
    &__horizontal-scroll {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 3rem;

        .project-card {
            min-width: 300px;
            width: 100%;
            max-width: 968px;
        }

        @media (min-width: $breakpoint-sm) {
            height: 640px;
        }

        @media (max-width: $breakpoint-sm) {
            height: 550px;
        }

        @media (max-width: $breakpoint-md) {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin: 0;
            overflow-x: auto;
            min-width: 670px;

            .project-card {
                margin: 0 1.5rem .75rem 0;
                height: 95%;

                &:first-of-type {
                    margin-left: 1.25rem;
                }
            }

            &-container {
                overflow-x: scroll;
            }
        }
    }


    &__content {
        padding: 3rem 2rem 3rem 4rem;

        @media (max-width: $breakpoint-md) {
            padding: 2rem 2.5rem 0 2.5rem;
        }
    }

    &__heading {
        color: var(--color-sea-green-900);
        max-width: 400px;
        margin-bottom: 1.5rem;
    }

    &__icon {
        font-size: 1.75rem;
        margin-left: 0.5rem;
        color: var(--color-sea-green-600);
        transform: translateY(6px);

        -webkit-transition: 100ms ease-out;
        -moz-transition: 100ms ease-out;
        -o-transition: 100ms ease-out;
        transition: 100ms ease-out;
    }

    &__thumbnail {
        padding: 1rem 2rem 1rem 0;
        max-height: 190px;
        max-width: 290px;
        width: 100%;

        @media (max-width: $breakpoint-md) {
            margin: auto;
            padding: 1rem 0 2.5rem 0;
        }
    }

    &__tags-mobile {
        display: none;
        margin: auto;
        padding: 0 2rem 2rem 2rem;
    }

    @media (max-width: $breakpoint-md) {
        &__tags-desktop {
            display: none;
        }

        &__tags-mobile {
            display: block;
            font-size: 0.85rem;
            line-height: 1.25rem;
            text-align: center;
        }
    }
}